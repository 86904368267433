{
  "id": "oryza",
  "name": "Gramene Oryza",
  "version": "v8",
  "description": "Comparative plant genomics focused on rice varieties",
  "url": "https://oryza.gramene.org",
  "ensemblURL": "https://oryza-ensembl.gramene.org",
  "ensemblSite": "https://oryza-ensembl.gramene.org",
  "ensemblRest": "https://data.gramene.org/pansite-ensembl-108",
  "grameneData": "https://data.gramene.org/oryza_v8",
  "curation": {
    "url": "http://curate.gramene.org/oryza_v7?since=4577&gene=",
    "taxa": {
      "39947013": "Oryza sativa Japonica"
    }
  },
  "targetTaxonId": 39946,
  "portals": ['browser','blast','pansites','curated','oryzaclimtools'],
  "portals2": ['pansites','browser','reactome','blast','oryzaclimtools','atlas','trackhub'],
  "showInMenu": true,
  "showNews": true,
  "showGuides": true,
  "showFeed": true,
  "showTweets": true,
  "date": "2024-08-09",
  "slides": [],
  details: {
    sequences: true,
    VEP: true,
    location: true,
    expression: true,
    homology: true,
    pathways: true,
    pubs: true,
    xrefs: true
  },
  "panSite" : {
    "sorghum_bicolor" : {
      "url": "https://sorghumbase.org/genes?idList=",
      "name": "SorghumBase",
      "svg": "./static/images/sorghum_logo.svg"
    },
    vitis_vinifera : {
      url: "https://vitis.gramene.org?idList=",
      name: "Gramene Grapevine",
      svg: "./static/images/grapevine_logo.svg"
    },
    zea_maysb73 : {
      url: "https://maize-pangenome.gramene.org?idList=",
      name: "Gramene Maize",
      svg: "./static/images/maize_logo.svg"
    }
  }
}
